import 'react-quill/dist/quill.snow.css';
import './App.css';

import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// redux
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider as QueryProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-license-pro';
import { store } from 'redux/store';
// react-query
import { queryClient } from 'api/queryClient';
// notistack
//
import AuthProvider from '@/settings/AuthProvider';
import ThemeProvider from 'theme/ThemeProvider';
import RouterProvider from 'routes/RouterProvider';
import SerialProvider from 'hooks/SerialProvider';
import GlobalErrorScreen from 'components/page/error/GlobalErrorScreen';

import 'dayjs/locale/ko';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import './i18n/config';

import * as Sentry from '@sentry/react';
import { setupNotifications } from '@/firebase-messaging-sw';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: 'dev',
    normalizeDepth: 6,
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: true }), new Sentry.BrowserTracing()],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

function App() {
    useEffect(() => {
        setupNotifications();
    }, []);
    return (
        <ErrorBoundary fallback={<GlobalErrorScreen />}>
            <ReduxProvider store={store}>
                <QueryProvider client={queryClient}>
                    <ThemeProvider>
                        <SnackbarProvider
                            style={{ marginTop: '50px', fontSize: '16px' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            autoHideDuration={2500}
                        >
                            <SerialProvider>
                                <AuthProvider>
                                    <RouterProvider />
                                </AuthProvider>
                            </SerialProvider>
                        </SnackbarProvider>
                    </ThemeProvider>
                    <ReactQueryDevtools initialIsOpen />
                </QueryProvider>
            </ReduxProvider>
        </ErrorBoundary>
    );
}

export default App;
